import { Dispatch, SetStateAction, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { InputSelectSearchAsync } from '.';
import { ESort } from '../../../../libs/interfaces';
import { useProjectsListPaginate } from '../../../../modules/project/queries/useProjects';
import { ProjectResponse } from '../../../../modules/project/responses/project.response';
import { EStyleSelect } from '../SelectInput';

export interface SelectProjectProps<FieldName> {
  label: string;
  name: keyof FieldName;
  required?: boolean;
  rules?: RegisterOptions;
  multiple?: boolean;
  isNewOne?: boolean;
  selectStyle?: EStyleSelect;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
}

export function SelectProject<FieldName>({
  label, name, required, rules, multiple, isNewOne, selectStyle, setOpenModal,
}: SelectProjectProps<FieldName>) {
  const [search, setSearch] = useState('');
  const { data, isLoading } = useProjectsListPaginate({
    page: 0, limit: 15, search, order: 'name', sort: ESort.ASC,
  }, { keepPreviousData: false, enabled: !!search });

  return (
    <InputSelectSearchAsync<FieldName, ProjectResponse>
      label={label}
      name={name}
      getOptionLabel={(option) => option.name as string}
      getOptionValue={(option) => option.id as unknown as string}
      data={data?.data ?? []}
      isLoading={isLoading}
      search={search}
      setSearch={setSearch}
      required={required}
      rules={rules}
      multiple={multiple}
      isNewOne={isNewOne}
      selectStyle={selectStyle}
      setOpenModal={setOpenModal}
    />
  );
}

SelectProject.defaultProps = {
  required: false,
  rules: {},
  multiple: false,
  isNewOne: false,
  setOpenModal: undefined,
  selectStyle: 'top',
};
