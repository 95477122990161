import {
  Autocomplete, FormGroup, TextField,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import {
  Controller,
  RegisterOptions, useFormContext,
} from 'react-hook-form';

interface IChoices {
  label:string,
  value:string,
}

interface Props<T> {
  choices: IChoices[]
  required?: boolean;
  defaultValue?: string[];
  rules?: RegisterOptions;
  name: keyof T;
  label: string;
  width?: string;
}

export default function InputSelectMultiple<T>({
  choices, required, name, label, rules,
}: Props<T>) {
  const { control, formState: { errors } } = useFormContext();
  const [open, setOpen] = useState(false);
  return (
    <FormGroup
      style={{
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <Controller
        render={({ field }) => (
          <Autocomplete
            open={open}
            multiple
            disableCloseOnSelect
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(event, value) => field.onChange(
              value?.map((item) => item.value),
            )}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            getOptionLabel={(option) => option?.label}
            defaultValue={field.value?.map((item) => choices.find((e) => e.value === item))}
            options={choices}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                fullWidth
                size="small"
                error={Boolean(_.get(errors, [name, 'ref'], false))}
                helperText={_.get(errors, [name, 'message'], '') as string}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        )}
        name={name as string}
        control={control}
        rules={{
          ...rules,
          required: {
            value: required as boolean,
            message: 'Ce champs est obligatoire',
          },
        }}
      />

    </FormGroup>
  );
}
InputSelectMultiple.defaultProps = {
  required: false,
  rules: {},
  width: '100%',
  defaultValue: '',
};
