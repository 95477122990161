export const Colors = {
  white: '#fff',
  black: '#000',
  blue: '#425df5',
  walterreBlue: '#113BC9',
  orange: '#FE8403',
  orange30: 'rgba(254, 132, 3, 0.3)',
  red: '#ff7675',
  yellow: 'rgba(255, 206, 86, 1)',
  grey: '#757575',
  eerieBlack: '#262626',
  ashGray: '#ACBFA4',
  beige: '#E2E8CE',
  orangeWheel: '#FF7F11',
  redWheel: '#FF1B1C',
  black10: 'rgba(0,0,0,0.1)',
  greyLow: '#eeeeee',
  black60: 'rgba(0, 0, 0, 0.6)',
};
