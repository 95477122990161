import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import TokenIcon from '@mui/icons-material/Token';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { ReactElement } from 'react';
import { ERoutePath } from '../../../../interface-adaptaters/interfaces/router/route.interface';
import { ETeam } from '../../../../modules/user/domain/user.entity';

interface IMenuList {
  router: ERoutePath;
  label: string;
  icon: ReactElement;
  permissions: ETeam[] | null;
  walterre?: boolean;
}

export const MENU_LIST: IMenuList[] = [{
  router: ERoutePath.LOGIN,
  label: 'Déconnexion',
  icon: <LogoutIcon />,
  permissions: null,
}, {
  router: ERoutePath.PROFIL,
  label: 'Profil',
  icon: <PersonIcon />,
  permissions: null,
}, {
  router: ERoutePath.USERS,
  label: 'Utilisateurs',
  icon: <PeopleIcon />,
  permissions: [ETeam.SUPER_ADMIN],
}, {
  router: ERoutePath.RECOMMENDATIONS,
  label: 'Préconisations',
  icon: <FormatListBulletedIcon />,
  permissions: [ETeam.SUPER_ADMIN],
}, {
  router: ERoutePath.TOKENS,
  label: 'Tokens',
  icon: <TokenIcon />,
  permissions: [ETeam.SUPER_ADMIN],
  walterre: true,
}];
