/* eslint-disable react/jsx-props-no-spreading */
import axios from 'axios';
import {
  Dispatch, SetStateAction, useEffect,
} from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertMessage } from '../../../../libs/alertMessage';
import { handleUseCaseError } from '../../../../libs/ddd/errors/HandleUseCaseError';
import {
  CreateProjectProps,
  ProjectProps,
} from '../../../../modules/project/domain/project.entity';
import { useProjectCreate } from '../../../../modules/project/queries/useProjects';
import { InputAddress } from '../../Form/InputAddress';
import { handleAddress } from '../../Form/InputAddress/utils';
import { SelectUser } from '../../Form/InputSelectSearchAsync/SelectUser';
import { InputText } from '../../Form/InputText';
import SaveButton from '../../shared/Buttons/SaveButton';
import { GenericModal } from '../../shared/Modals/GenericModal/index';
import { DivContainer, Wrapper } from '../style';
import { VerticalL } from '../verticalLine';
import { BuildingArray } from './buildingArray';
import { useFormProjectHook, useToggleModal } from './hooks';
import { TitleStyled } from './style';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  closeModal: Dispatch<SetStateAction<boolean>>;
}
export function ModalProject({ open, setOpen, closeModal }: Props) {
  const navigate = useNavigate();
  const methods = useFormProjectHook();

  useToggleModal(closeModal, open);

  const addProject = useProjectCreate();
  const onSubmit = async (values: CreateProjectProps) => {
    const latitude = values.address?.geometry?.coordinates[1];
    const longitude = values.address?.geometry?.coordinates[0];
    let updatedValues = {};

    if (latitude && longitude) {
      const elevation = await axios.get(`https://api.elevationapi.com/api/Elevation?lat=${latitude}&lon=${longitude}&dataSet=SRTM_GL3`);

      updatedValues = {
        ...values,
        latitude,
        longitude,
        altitude: elevation.data.geoPoints[0].elevation,
      };
    }

    const dataToSend = latitude && longitude ? updatedValues : values;

    addProject.mutateAsync(handleAddress(dataToSend))
      .then(({ id }) => {
        closeModal(false);
        navigate(`/project/${id}`);
      }).catch((error) => alertMessage.error(handleUseCaseError(error)));
  };
  /** Update building name if only one */
  const projectName = methods.watch('name');
  useEffect(() => {
    methods.setValue(`buildings.${0}.name`, projectName);
  }, [methods, projectName]);

  return (
    <GenericModal
      title="Projet"
      open={open}
      setOpen={setOpen}
    >
      <FormProvider
        {...methods}
      >

        <form
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Wrapper>
            <DivContainer>
              <TitleStyled>
                Informations générales
              </TitleStyled>
              <InputText
                name="name"
                label="Nom du projet"
                required
                rules={{
                  pattern: {
                    value: /^[A-Za-z0-9 œéèêëàâäîïôöûüç'&()-]*$/,
                    message: 'N\'accepte pas les caractères ! @ # $ % ^ " * _ + = < > ? ; : , . / \\ { } [ ]',
                  },
                }}
              />
              <SelectUser<ProjectProps>
                label="Admin en charge"
                name="admin"
              />
            </DivContainer>
            <VerticalL />
            <DivContainer>
              <TitleStyled>
                Informations bâtiment(s)
              </TitleStyled>
              <InputAddress />
              <BuildingArray />
            </DivContainer>
          </Wrapper>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <SaveButton />
          </div>
        </form>
      </FormProvider>
    </GenericModal>
  );
}
