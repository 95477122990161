import {
  QueryFunction,
  QueryKey,
  useQuery as useBaseQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

export const useQuery = <T, E>(
  queryKey: QueryKey, queryFn: QueryFunction<T, QueryKey>, options?: Omit<UseQueryOptions<T, E, T, QueryKey>, 'queryFn' | 'queryKey'> | undefined,
): UseQueryResult<T, E> => useBaseQuery<T, E>(queryKey, queryFn, {
  retry: 1,
  refetchOnWindowFocus: process.env.NODE_ENV === 'production',
  ...options,
});

export const useRefresh = () => {
  const queryClient = useQueryClient();
  return (query: QueryKey): Promise<void> => queryClient.invalidateQueries(query);
};
