import { GenericButtonProps } from '../GenericButton';
import { SaveButtonStyled } from './style';

export default function SaveButton({ ...props } : GenericButtonProps) {
  return (
    <SaveButtonStyled type="submit" variant="outlined" {...props} />
  );
}

SaveButton.defaultProps = {
  label: 'Enregistrer',
};
