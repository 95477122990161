import {
  Autocomplete, FormControl, TextField,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import {
  Controller,
  RegisterOptions, useFormContext,
} from 'react-hook-form';
import { FieldName } from '../../Project/style';

export interface IChoices {
  label: string,
  value: string,
  show?: boolean,
}
export enum EStyleSelect {
  // eslint-disable-next-line no-unused-vars
  START = 'start',
  // eslint-disable-next-line no-unused-vars
  TOP = 'top',
}

interface Props<T> {
  choices: IChoices[]
  required?: boolean;
  rules?: RegisterOptions;
  name: keyof T;
  label: string;
  selectStyle?: EStyleSelect;
  width?: string;
  disabled?: boolean;
  fullwidth?: boolean;
  consumptionStyle?: boolean;
  noOptionsText?: string;
  rafStyle?: boolean;
}

export default function SelectInput<T>({
  choices, required, name, label, rules, selectStyle, disabled, fullwidth, width, consumptionStyle, noOptionsText, rafStyle,
}: Props<T>) {
  const { control, formState: { errors } } = useFormContext();
  const [open, setOpen] = useState(false);
  return (
    <FormControl
      disabled={disabled}
      fullWidth={fullwidth}
      style={{
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width,
        }}
      >

        {selectStyle === 'start' ? (

          <FieldName
            style={
            consumptionStyle
              ? {
                minWidth: '100px',
              } : rafStyle ? {
                width: '250px',
                textAlign: 'center',
              }
                : {
                  width: '200px',
                }
          }
          >
            {label}

          </FieldName>

        ) : null}
        <Controller
          render={({ field }) => (
            <Autocomplete
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(event, value) => field.onChange(
                value?.value,
              )}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              getOptionDisabled={(option) => !(option.show ?? true)}
              getOptionLabel={(option) => option.label}
              defaultValue={choices.filter((choice) => choice?.value === field?.value)[0]}
              options={choices}
              disabled={disabled}
              fullWidth
              noOptionsText={noOptionsText}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={selectStyle === 'top' ? label : ''}
                  fullWidth
                  size="small"
                  disabled={disabled}
                  required={required}
                  error={Boolean(_.get(errors, `${name as string}.ref`, false))}
                  helperText={_.get(errors, `${name as string}.message`, '') as string}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          )}
          name={name as string}
          control={control}
          rules={{
            ...rules,
            required: {
              value: required as boolean,
              message: 'Ce champs est obligatoire',
            },
          }}
        />
      </div>

    </FormControl>

  );
}
SelectInput.defaultProps = {
  required: false,
  rules: {},
  selectStyle: 'top',
  width: '100%',
  disabled: false,
  fullwidth: true,
  consumptionStyle: false,
  noOptionsText: 'Aucune donnée',
  rafStyle: false,
};
