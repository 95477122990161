import styled from 'styled-components';

export const WrapperStyle = styled.div`
  margin: 10px auto 0;
`;

export const FormWrapperStyle = styled.section`
  border-radius: 5px;
  box-shadow: 0px 1px 4px #e3e3e3; // We should not write that, we have color file for this property (copy MainBox pageContact)
  min-height: 200px;
  margin: 20px;
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow: auto;
`;

export const GenericButtonFormWrapperStyle = styled.section`
  display: flex;
  justify-content: center;
  column-gap: 10px;
  margin: 20px auto;
`;

export const FormWrapperStyleRemark = styled.section`
  border-radius: 5px;
  box-shadow: 0px 1px 4px #e3e3e3; // We should not write that, we have color file for this property (  margin: 20px;
  padding: 5px;
  max-height: calc(100vh - 100px);
  overflow: auto;
  width: 100%;
`;
